function buildValidationRulesDe (form) {
  return {
    'contact_form[name]': {
      presence: {
        message: form.dataset.translationFieldRequired
      }
    },
    'contact_form[phone]': {
      presence: {
        message: form.dataset.translationFieldRequired
      }
    },
    'contact_form[email]': {
      presence: {
        message: form.dataset.translationFieldRequired
      },
      email: {
        message: form.dataset.translationEnterValidEmail
      }
    },
    'contact_form[dealership]': {
      presence: {
        message: form.dataset.translationFieldRequired
      }
    }
  }
}

export default function buildValidationRules (form) {
  if (window.Carwow.Translations.locale === 'de') {
    return buildValidationRulesDe(form)
  }

  // uk / es rules
  const rules = {
    'contact_form[name]': {
      presence: {
        message: form.dataset.translationFieldRequired
      }
    },
    'contact_form[phone]': {
      presence: {
        message: form.dataset.translationFieldRequired
      }
    },
    'contact_form[email]': {
      presence: {
        message: form.dataset.translationFieldRequired
      },
      email: {
        message: form.dataset.translationEnterValidEmail
      }
    },
    'contact_form[dealership]': {
      presence: {
        message: form.dataset.translationFieldRequired
      }
    },
    'contact_form[website]': {
      presence: {
        message: form.dataset.translationFieldRequired
      }
    },
    'contact_form[hear_about_us]': {
      presence: {
        message: form.dataset.translationFieldRequired
      }
    },
    'contact_form[address_line_1]': {
      presence: {
        message: form.dataset.translationFieldRequired
      }
    },
    'contact_form[city]': {
      presence: {
        message: form.dataset.translationFieldRequired
      }
    },
    'contact_form[postcode]': {
      presence: {
        message: form.dataset.translationFieldRequired
      }
    }
  }
  if (window.Carwow.Translations.locale === 'en') {
    rules['contact_form[phone]'].format = {
      pattern: /^(((\+44)? ?(\(0\))? ?)|(0))( ?[0-9]{3,4}){3}$/,
      message: 'Please specify a valid phone number'
    }
  }
  return rules
}
