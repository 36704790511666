import Analytical from '@carwow/carwow_theme/app/javascript/helpers/analytical'
import initFormValidation from '@carwow/carwow_theme/app/javascript/helpers/form_validation'
import buildValidationRules from './home/validation_rules'
import Cookie from 'js-cookie'

function init () {
  Analytical.event('Dealer viewed signup', {})
  const form = document.getElementById('new_contact_form')
  if (!form) { return }

  const action = form.querySelector('button[type=submit]')
  initFormValidation(
    form,
    buildValidationRules(form),
    {
      submitHandler: () => {
        const formData = new URLSearchParams(new FormData(form))
        const jwt = Cookie.get('session_token')
        const formUrl = form.getAttribute('action')
        action.setAttribute('disabled', '')
        Analytical.event('Dealer clicked apply now', {})
        fetch(formUrl, {
          method: 'POST',
          headers: {
            authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: formData
        }).then(response => response.json())
          .then(data => {
            if (data.errors && Object.keys(data.errors).length > 0) {
              action.removeAttribute('disabled')
            } else {
              document.querySelector('.home__join-us-form-section').classList.add('hidden-content')
              document.querySelector('.home__join-us-success-section').classList.remove('hidden-content')
              document.querySelector('.home__join-us-success-section').scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
          })
          .catch(() => {
            action.removeAttribute('disabled')
          })
      }
    }
  )
}
document.addEventListener('DOMContentLoaded', init)
